import { useMutation } from "@tanstack/react-query";
import { apiFactory } from "../utils/api";
import useAuth from "../auth/useAuth";

export const useCreateCyberApp = () => {
  const { getToken } = useAuth();

  const createCyberApp = async (payload) => {
    const token = await getToken();
    const api = apiFactory(token);

    return api.business.createCyberApp(payload);
  };
  return useMutation(createCyberApp);
};
