export const industryOptions = [
  { value: "Consulting", label: "Consulting" },
  { value: "Design", label: "Design" },
  { value: "Insurance", label: "Insurance" },
  { value: "Non-Profit", label: "Non-Profit" },
  { value: "Real Estate", label: "Real Estate" },
  { value: "Technology", label: "Technology" },
  { value: "Trustee", label: "Trustee" },
  { value: "Other", label: "Other" },
];

export const orgOptions = [
  { value: "Private", label: "Private" },
  { value: "NonProfit", label: "Nonprofit" },
  { value: "Public", label: "Publicly Traded" },
  { value: "PublicSector", label: "Public Sector" },
];

export const wbOptions = [
  { value: "AMWINS", label: "Amwins" },
  { value: "INSURE_TRUST", label: "InsureTrust" },

];

export const aggregateOptions = [
  { value: "500,000", label: "$500,000" },
  { value: "1,000,000", label: "$1,000,000" },
  { value: "2,000,000", label: "$2,000,000" },
  { value: "3,000,000", label: "$3,000,000" },
  { value: "4,000,000", label: "$4,000,000" },
  { value: "5,000,000", label: "$5,000,000" },
];

export const retentionOptions = [
  { value: "1,000", label: "$1,000" },
  { value: "2,500", label: "$2,500" },
  { value: "5,000", label: "$5,000" },
  { value: "10,000", label: "$10,000" },
  { value: "25,000", label: "$25,000" },
];
