import { useQuery } from "@tanstack/react-query";
import { apiFactory } from "../utils/api";
import useAuth from "../auth/useAuth";

export const useGetApplication = (email, existingApp) => {
  const { getToken } = useAuth();
  
  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const getApplication = async () => {
    if (!email || !validateEmail(email)) {
      return "";
    }
    const token = await getToken();
    const api = apiFactory(token);
    var data = api.business.getApplication(encodeURIComponent(email));
    if (data) {
      return data;
    } else {
      return "";
    }
  };
  return useQuery(["get_application", email], getApplication, {
    enabled: existingApp && email !== "",
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
  });
};
