import React from "react";
import styled from "styled-components";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { useCreateCyberApp } from "../hooks/useCreateCyberApp";
import { useUpdateApplication } from "../hooks/useUpdateApplication";
import { useStore } from "../utils/store";
import useAuth from "../auth/useAuth";
import { mobile } from "../styles/responsive";
import { omit } from "lodash";

const NavBar = () => {
  const { isAuthenticated, logout } = useAuth0();
  const { email } = useAuth();
  const location = useLocation();
  const path = location.pathname;
  const displaySaveAndExit = path.includes("/application") ? true : false;
  const form = useStore((state) => state.form);
  const updateForm = useStore((state) => state.updateForm);
  const navigate = useNavigate();
  const postMutation = useCreateCyberApp();
  const putMutation = useUpdateApplication(form.id);

  const handleLogout = () => {
    logout({
      logoutParams: { returnTo: "https://especialty.com/" },
    });
  };

  const saveAndExit = async () => {
    const newForm =
      form.domainPrim[0]?.domainPrim === "" ? omit(form, "domainPrim") : form;
    const payload = JSON.stringify(newForm);
    if (form.id === 0 || form.id === undefined) {
      postMutation.mutate(payload, {
        onSuccess: (data) => {
          navigate("/");
          updateForm({ id: data });
        },
        onError: (error) => {
          console.log(error);
        },
      });
    } else {
      putMutation.mutate(payload, {
        onSuccess: () => {
          navigate("/");
        },
        onError: (error) => {
          console.log(error);
        },
      });
    }
  };

  return (
    <Wrapper>
      <Row>
        {isAuthenticated && !displaySaveAndExit && (
          <LogoutButton onClick={handleLogout}>Log Out</LogoutButton>
        )}
        {displaySaveAndExit && <Save onClick={saveAndExit}>Save & Exit</Save>}
      </Row>
    </Wrapper>
  );
};

const User = styled.div`
  margin: auto;
  padding-right: 20px;

  ${mobile} {
    font-size: 13px;
  }
`;

const Save = styled.button`
  background: ${(props) => props.theme.cta};
  color: ${(props) => props.theme.offblack};
  font-size: 18px;
  padding: 12px 24px;
  border: none;
  font-weight: 500;
  border-radius: 200px;
  margin-right: 20px;
  text-decoration: none;

  :hover {
    cursor pointer;
    background: ${(props) => props.theme.hovercta};
  }`;

const Wrapper = styled.nav`
  padding: 40px 40px;
  align-self: flex-end;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const LogoutButton = styled(NavLink)`
  border-radius: 30px;
  background: ${(props) => props.theme.white};
  color: ${(props) => props.theme.offblack};
  border: 2px solid ${(props) => props.theme.offblack};
  cursor: pointer;
  padding: 8px 24px;
  font-size: 14px;

  ${mobile} {
    padding: 6px 12px;
    font-size: 12px;
  }

  &:hover {
    border: 2px solid ${(props) => props.theme.blue};
    background: ${(props) => props.theme.blue};
    color: ${(props) => props.theme.white};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

export default NavBar;
